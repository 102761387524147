import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 60 min. `}<meta itemProp="prepTime" content="PT60M" /></h5>
    <h5>{`Cook time: 60 min. `}<meta itemProp="cookTime" content="PT60M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Arborio rice, 1 1/2 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chicken stock, 4 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Mushrooms, 8 oz. sliced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Onions, 1 large or 2 small diced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parmesan cheese, 1 cup grated</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Dry white wine, 1/2 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Unsalted butter, 4 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cayenne pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Frozen peas, 1 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Frozen corn, 1/2 cup</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Dutch oven</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Frying pan</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Slice mushrooms</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Dice onions</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate parmesan</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Mix rice, 3 cups chicken stock, 1 cup water in Dutch oven. Cover and bake 45 minutes at 350F.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`While rice is cooking, sautee 1 tbs. butter and mushrooms, about 2 minutes. Add onions and cook until mushrooms are soft and onions translucent. Set aside.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`When rice is finished, move to low heat on the stovetop.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add to the rice: remaining chicken stock, parmesan, wine, mushrooms and onions, butter, cayenne, salt, and pepper. Stir 2-3 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add peas and corn until heated through.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      